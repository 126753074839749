import { makeStyles } from '@material-ui/core/styles';

export const useNaviStyles = makeStyles(theme => ({
  navLink: {
    color: '#FFF',
    '&:hover': {
      color: '#FFF'
    },
    [theme.breakpoints.down("sm")]: {
      color: '#000',
      '&:hover': {
        color: '#000'
      }
    }
  },
  snackbarStyle: {
    backgroundColor: theme.palette.primary.main
  }
}));