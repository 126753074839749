import { MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React from 'react';

class NewPurchaseForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hotels: []
        }
    }

    componentDidMount() {
        let hotels = [];
        axios.get("https://skiogmagi.no:8443/getAllHotels")
            .then(response => {
                response.data.forEach(hotel => {
                    let hotelObj = {
                        id: hotel.id,
                        name: hotel.name
                    };
                    hotels.push(hotelObj);
                });
                this.setState({ hotels: hotels });
            });
    }

    render() {
        const { values, handleChange } = this.props;
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Kjøps informasjon
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField required
                            id="hotel" name="hotel"
                            label="Hotell" select
                            fullWidth helperText="Vennligst velg hotell"
                            onChange={(e) => handleChange(0, e.target.value)}
                            margin="normal" value={values.hotel}>
                            {this.state.hotels.map(hotel => (
                                <MenuItem key={hotel.id} value={hotel}>
                                    {hotel.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField required 
                            id="maxResidentalsAmount" name="maxResidentalsAmount" 
                            label="Maks Antall personer i rommet" type="number" 
                            fullWidth value={values.maxResidentalsAmount}
                            onChange={(e) => handleChange(1, e.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField required 
                            id="totalRooms" name="totalRooms" 
                            label="Antall slike rom i hotellet?" type="number" 
                            fullWidth value={values.totalRooms}
                            onChange={(e) => handleChange(2, e.target.value)}/>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default NewPurchaseForm;