import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useStyles } from "./ChangeTicketStyle";


export default function ChangeTicket() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <div className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                        Endre Billett
                    </Typography>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        Endring av billett gjøres ved å ta kontakt med en av arrangørene
                    </Typography>
                    <Typography variant="h6" align="center" color="textSecondary" paragraph>
                        (Selvbetjent endring kommer snart)
                    </Typography>
                </Paper>
            </div>
        </React.Fragment>
    );
}