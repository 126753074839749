import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const BookingRow = (props) => {
    return (
        <TableRow>
            <TableCell>#{props.orderId}</TableCell>
            <TableCell>{props.name}</TableCell>
            <TableCell>{props.email}</TableCell>
            <TableCell>{props.phone}</TableCell>
            <TableCell>{props.hotel}</TableCell>
            <TableCell>{props.type}</TableCell>
            <TableCell>{(props.responsible === props.orderId) ? (
                <p>Ja</p>
            ) : (
                <p>Nei</p>
            )}</TableCell>
        </TableRow>
    );
}

export default BookingRow;