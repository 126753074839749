import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { withRouter } from "react-router-dom";
import Button from "../../components/material-kit-components/CustomButtons/Button";
import "../../index.css";
import { useStyles } from "./FooterStyle";


function Footer({history}) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            {/* Footer */}
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12} style={{ backgroundColor: '#1C2331' }}>
                    <Container maxWidth="lg">
                        <Grid container justify="center" alignItems="center" spacing={3} style={{ padding: 20 }}>
                            <Grid item xs={12} md={4} className={classes.colHeight}>
                                <h3 style={{ color: "#FFFFFF", fontWeight: 'bold', textTransform: "uppercase", textAlign: "center" }}>
                                    Ski & Magi
                                </h3>
                                <hr style={{ width: "90px", color: "#009BDF" }}></hr>
                                <p style={{ color: "#FFFFFF", fontSize: "18px", maxWidth: "400px", marginLeft: "auto", marginRight: "auto" }}>
                                  På Ski & Magi reiser omlag 400 studenter på en tredagers
                                  skitur i den norske fjellheimen. Dette har vært en
                                  tradisjon siden tidlig 90-tall og er det eldste arrangementet
                                  for studenter i Ålesund.
                                </p>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.colHeight}>
                                <h3 style={{ color: "#FFFFFF", fontWeight: "bold", textTransform: "uppercase", textAlign: "center" }}>
                                    Contact</h3>
                                <hr style={{ width: "70px", color: "#009BDF" }}></hr>
                                <div>
                                    <p style={{ color: "#FFFFFF", textAlign: "center", fontSize: "18px" }}>
                                        <i style={{ marginRight: "10px" }} className="fas fa-home"></i>Larsgården 2, 6009 Ålesund
                                    </p>
                                    <p style={{ color: "#FFFFFF", textAlign: "center", fontSize: "18px" }}>
                                        <i style={{ marginRight: "10px" }} className="fas fa-envelope"></i>skiogmagi@astudent.no
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.colHeight}>
                                <h3 style={{ color: "#FFFFFF", fontWeight: "bold", textTransform: "uppercase", textAlign: "center" }}>
                                    Social</h3>
                                <hr style={{ width: "50px", color: "#009BDF" }}></hr>
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={12} style={{ textAlign: "center" }}>
                                        <Button justIcon round color="facebook" onClick={() => {
                                            window.open("https://www.facebook.com/SkiogMagi/", "_blank")
                                            }}>
                                            <i className={" fab fa-facebook"} />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: "center" }}>
                                        <Button justIcon round color="google" onClick={() => {
                                            window.open("https://www.instagram.com/skiogmagi/?hl=nb", "_blank")
                                            }}>
                                            <i className={"fab fa-instagram"}></i>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                <Grid item xs={12} style={{ backgroundColor: '#161C27' }}>
                    <Typography style={{ color: "#FFFFFF", marginTop: 5, marginBottom: 5 }} variant="body2" align="center">
                        {'Copyright © '}
                        Ålesund Studentsamfunn
                        {" " + new Date().getFullYear()}
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default withRouter(Footer);