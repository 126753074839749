import { Box } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React from 'react';
import Button from '../../../../components/material-kit-components/CustomButtons/Button';
import { useStyles } from './NewHotelStyle';


export default function NewHotel() {
    const classes = useStyles();
    // State
    const [name, setName] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(false);

    const handleChange = (e) => {
        if (e.trim().length < 3) {
            setError(true);
        } else {
            setError(false);
        }
        setName(e)
    }

    const handleClickOpen = () => {
        if (name.trim().length >= 3) {
            setOpen(true);
        }
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        axios.post("https://skiogmagi.no:8443/newHotel", {
            name: name
        }).then(response => {
            window.location.replace("/admin");
        }).catch(error => {
        })
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h4" align="center">
                        Legg til hotell
                    </Typography>
                    <Grid container className={classes.mt}>
                        <Grid item xs={12}>
                            <TextField required={true} error={error} 
                                id="hotelName" name="hotelName" 
                                label="Navn på hotell" type="text"
                                fullWidth onChange={(e) => handleChange(e.target.value)}
                                defaultValue={name}/>
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="center" className={classes.mt}>
                        <Button color="success" className={classes.button} onClick={handleClickOpen}>
                            Legg til
                        </Button>
                    </Box>
                </Paper>
                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        {"Godkjenning av hotell"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Ved samtykke vil hotellet ved navn:<b> {name} </b> bli lagt til i databasen.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="danger">
                            Avbryt
                        </Button>
                        <Button onClick={handleSubmit} color="info" autoFocus>
                            Navn er riktig
                        </Button>
                    </DialogActions>
                </Dialog>
            </main>
        </React.Fragment>
    );
}