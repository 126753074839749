import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React from 'react';

export default function TicketSearch(props) {
    const[ valid, setValid ] = React.useState(false);
    const { handleChange } = props;

    const handleInputChange = (event) => {
        axios.get("https://skiogmagi.no:8443/allOrderIds/" + event.target.value)
            .then(response => {
                if (response.status === 200) {
                    handleChange(0, response.data.orderId);
                    handleChange(2, response.data.name);
                    handleChange(3, response.data.email);
                    handleChange(4, response.data.phone);
                    handleChange(5, response.data.birth);
                    setValid(true);
                }
            }).catch(error => {
                setValid(false);
            });
    }

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Søk etter billett
            </Typography>
            <Grid container spacing={3}>
                {/* Order ID */}
                <Grid item xs={12}>
                    <TextField
                        error={!valid} id="orderId" name="orderId"
                        label="Ordre ID" type="number"
                        fullWidth onChange={(e) => handleInputChange(e)}
                        margin="normal" variant="outlined"
                        defaultValue=""/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}