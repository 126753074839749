import { Divider, List } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
/* Material UI Icons */
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CreateIcon from '@material-ui/icons/Create';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import GetAppIcon from '@material-ui/icons/GetApp';
import HomeIcon from '@material-ui/icons/Home';
import HotelIcon from '@material-ui/icons/Hotel';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PublishIcon from '@material-ui/icons/Publish';
import TimerIcon from '@material-ui/icons/Timer';
import clsx from 'clsx';
import React from 'react';
// React Router
import { Link } from 'react-router-dom';
import { useStyles } from './AdminNavbarStyle';



export default function Dashboard() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton edge="start" color="inherit" aria-label="open drawer"
                        onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
                        <MenuIcon />
                    </IconButton>
                    <Link to="/admin" className={classes.title} style={{ color: 'white'}}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            Ski & Magi Admin Panel
                        </Typography>
                    </Link>
                    {/* Kan ha notifications hver gang bruker pr */}
                    <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }} open={open}>
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider/>
                {/* Contains drawer menu */}
                <List>
                    {/* Dashboard menu */}
                    <Link to="/admin">
                        <ListItem button>
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                    </Link>
                    {/* Timer menu */}
                    <ListItem button>
                        <ListItemIcon>
                            <TimerIcon />
                        </ListItemIcon>
                        <ListItemText primary="Start timer" />
                    </ListItem>
                    {/* Purchase menu */}
                    <Link to="/registrer-kjop">
                        <ListItem button>
                            <ListItemIcon>
                                <AddIcon />
                            </ListItemIcon>
                            <ListItemText primary="Legg til kjøp" />
                        </ListItem>
                    </Link>
                    {/* Change ticket menu */}
                    <Link to="/change">
                        <ListItem button>
                            <ListItemIcon>
                                <CreateIcon />
                            </ListItemIcon>
                            <ListItemText primary="Endre billett" />
                        </ListItem>
                    </Link>
                    {/* Room menu */}
                    <Link to="/add-room">
                        <ListItem button>
                            <ListItemIcon>
                                <HotelIcon />
                            </ListItemIcon>
                            <ListItemText primary="Legg til rom" />
                        </ListItem>
                    </Link>
                    {/* Hotel menu */}
                    <Link to="/add-hotel">
                        <ListItem button>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Legg til hotell" />
                        </ListItem>
                    </Link>
                    {/* Bookings menu */}
                    <Link to="/bookings">
                        <ListItem button>
                            <ListItemIcon>
                                <EventAvailableIcon />
                            </ListItemIcon>
                            <ListItemText primary="Bestillinger" />
                        </ListItem>
                    </Link>
                    {/* Upload menu */}
                    <Link to="/upload">
                        <ListItem button>
                            <ListItemIcon>
                                <PublishIcon />
                            </ListItemIcon>
                            <ListItemText primary="Last opp kjøp" />
                        </ListItem>
                    </Link>
                    {/* Download menu */}
                    <Link to="/download">
                        <ListItem button>
                            <ListItemIcon>
                                <GetAppIcon />
                            </ListItemIcon>
                            <ListItemText primary="Last ned bestillinger" />
                        </ListItem>
                    </Link>
                </List>
            </Drawer>
        </React.Fragment>
    );
}