import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
    },
    table: {

    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      backgroundColor: '#EFEFEF',
      borderRadius: 5,
      paddingLeft: 10
    },
    iconButton: {
      padding: 7,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    icon: {
      marginLeft: 5,
      color: '#666565'
    }
}));