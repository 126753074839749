import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
// core components
import Header from "./../../components/material-kit-components/Header/Header";
import Button from "./../../components/material-kit-components/CustomButtons/Button.js";

import styles from "./../../assets/material-kit-assets/jss/material-kit-react/views/componentsSections/navbarsStyle";

import Logo from "../../assets/img/logo_white.png";
import { useNaviStyles } from "./NaviLinkStyle";

import { useWindowDimensions } from "../../util/useWindowDimensions";

const useStyles = makeStyles(styles);

export default function Navbar() {
	const [menuOpen, setMenuOpen] = React.useState(false);
	const classes = useStyles();
	const naviStyle = useNaviStyles();
	const { width } = useWindowDimensions();

	const toggleMenu = () => {
		if (width < 960) {
			setMenuOpen(!menuOpen);
		}
	};

	return (
		<div className={classes.section}>
			<Header
				brand={Logo}
				color="skiOgMagiBlue"
				menuState={menuOpen}
				menuToggle={toggleMenu}
				rightLinks={
					<List className={classes.list}>
						<Link to="/" className={naviStyle.navLink} onClick={toggleMenu}>
							<ListItem className={classes.listItem}>
								<Button className={classes.navLink} color="transparent">
									FORSIDE
								</Button>
							</ListItem>
						</Link>
						{/* <Link to="/change-ticket" className={naviStyle.navLink} onClick={toggleMenu}> */}
						<ListItem className={classes.listItem}>
							<Button disabled={true} className={classes.navLink} color="transparent">
								ENDRE BILLETT
							</Button>
						</ListItem>
						{/* </Link> */}
						{/* <Link to="/see-ticket" className={naviStyle.navLink} onClick={toggleMenu}> */}
						<ListItem className={classes.listItem}>
							<Button disabled={true} className={classes.navLink} color="transparent">
								SE BILLETT
							</Button>
						</ListItem>
						{/* </Link> */}
						{/* <Link to="/booking" className={naviStyle.navLink} onClick={toggleMenu}> */}
						<ListItem className={classes.listItem}>
							<Button disabled={true} className={classes.navLink} color="transparent">
								Booking
							</Button>
						</ListItem>
						{/* </Link> */}
						<Link to="/merch" className={naviStyle.navLink} onClick={toggleMenu}>
							<ListItem className={classes.listItem}>
								<Button className={classes.navLink} color="transparent">
									MERCH
								</Button>
							</ListItem>
						</Link>
						<Link to="/program" className={naviStyle.navLink} onClick={toggleMenu}>
							<ListItem className={classes.listItem}>
								<Button className={classes.navLink} color="transparent">
									PROGRAM
								</Button>
							</ListItem>
						</Link>
					</List>
				}
			/>
		</div>
	);
}
