import axios from 'axios';
import {AUTH_START, AUTH_SUCCESS, AUTH_FAILED, AUTH_LOGOUT} from "./actionTypes";
import { setCookie, getCookie, deleteCookie } from '../../util/cookieService';

const authStart = () => {
    return {
        type: AUTH_START
    };
};

const authSuccess = (idToken) => {
    return {
        type: AUTH_SUCCESS,
        idToken: idToken,
    };
};

const authFailed = (error) => {
    return {
        type: AUTH_FAILED,
        error: error
    };
};

export const logout = () => {
    deleteCookie('Authorization');
    return {
        type: AUTH_LOGOUT
    };
};

// TODO Need to be changed to work with åss backend instead of firebase test env
export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password,
            returnSecureToken: true
        };
        let url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCL67OON3I470uXtRJKQuUlGwqa9yQDL0c';
        axios.post(url, authData)
            .then(response => {
                setCookie("Authorization", response.data.idToken, 
                    new Date(new Date().getDay()));
                dispatch(authSuccess(response.data.idToken));
            })
            .catch(err => {
                // Firebase stuff
                dispatch(authFailed(err.response.data.error));
            });
    };
};

// TODO Firebase reauuthenticate the token
export const authCheckState = () => {
    return dispatch => {
        const tokenFound = getCookie('Authorization') !== '';
        if (!tokenFound) {
            dispatch(logout());
        } else {
            const token = getCookie("Authorization");
            dispatch(authSuccess(token));
        }
    };
};