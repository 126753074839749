import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    logo: {
        marginTop: 20,
        marginBottom: 30
    },
    image: {
        height: '135px',
    },
    '@media (max-width: 450px)': {
        image: {
            height: '90px'
        }
    },
    video: {
        boxShadow: '10px 10px 15px -10px rgba(0,0,0,0.75)',
        marginBottom: 20
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    imageSection: {
        marginTop: theme.spacing(3),
    },
    rootGrid: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        width: 500,
        height: 350,
    },
    avatar: {
        margin: "0 auto",
        width: 200,
        height: 200,
    },
    instagram: {
        margin: "0 auto",
        marginTop: 15,
        width: 160,
        height: 160,
    }
}));