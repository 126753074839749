import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import React from 'react';

class PurchaseReview extends React.Component {
    render() {
        const { values } = this.props;
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Bestillingssammendrag
                </Typography>
                <Grid item container direction="column" xs={12}>
                    <Grid container>
                        {/* Order ID */}
                        <Grid item xs={6}>
                            <Typography gutterBottom>
                                Ordre ID:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom>{values.orderId}</Typography>
                        </Grid>
                        {/* Full name of the person with the order*/}
                        <Grid item xs={6}>
                            <Typography gutterBottom>
                                Fullt Navn:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom>{values.fullName}</Typography>
                        </Grid>
                        {/* Email of the person with the order*/}
                        <Grid item xs={6}>
                            <Typography gutterBottom>
                                Epost:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom>{values.email}</Typography>
                        </Grid>
                        {/* Persons' phone number */}
                        <Grid item xs={6}>
                            <Typography gutterBottom>
                                Mobil:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom>{values.phone}</Typography>
                        </Grid>
                        {/* Persons' birth date*/}
                        <Grid item xs={6}>
                            <Typography gutterBottom>
                                Fødselsdato:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom>{format(values.birth, "dd/MM/yyyy")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default PurchaseReview;