import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Footer from "components/Footer/Footer";
import React from "react";
import ReactPlayer from "react-player";
import Logo from "../../../assets/img/Skiogmagiheader5.jpg";
// Icons
import { useStyles } from "./HomeStyle";
import "./HomeStyleCSS.css";



export default function Home() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Grid container justify="center" alignItems="center" className={classes.logo}>
            {/* Ski & magi logo */}
            <img className={classes.image} src={Logo} alt="Ski & Magi logo" />
          </Grid>
          <Grid container justify="center" alignItems="center">
            {/* Embedded youtube vid */}
            {/*<ReactPlayer className={classes.video} url="https://www.youtube.com/watch?v=mUdqfE3nM7A&feature=youtu.be&fbclid=IwAR1BOfLcwg4OUZo89PC5q-MUzu55r1jXeGTAvaCqyEgDT0e-pAoAtxpjjCQ" playing={true} onReady/>*/}
            <ReactPlayer className={classes.video}
              url='https://www.youtube.com/watch?v=mUdqfE3nM7A&feature=youtu.be&fbclid=IwAR1BOfLcwg4OUZo89PC5q-MUzu55r1jXeGTAvaCqyEgDT0e-pAoAtxpjjCQ&origin=http://skiogmagi.no'
              playing
              muted
              config={{
                file: {
                  attributes: {
                    autoPlay: true,
                    muted: true
                  }
                }
              }}
            />
          </Grid>
        </Paper>
      </main>
      <Footer />
    </React.Fragment>
  );
}