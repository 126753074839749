import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import axios from 'axios';
import React from "react";
import { useStyles } from "../SeeTicketStyle";


export default function LoginForm(props) {
    const classes = useStyles();
    const [groupname, setGroupname] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [feedback, setFeedback] = React.useState("");

    const { nextStep, setTicketDetails } = props;

    const handleChange = (input, value) => {
        switch (input) {
          case 0:
            setGroupname(value);
            break;

          case 1:
            setPassword(value);
            break;

          default: 
            break;
        }
    }

    const handleLogin = (e) => {
      e.preventDefault();
      axios.post("https://skiogmagi.no:8443/getTicket", {
        username: groupname,
        password: password
      }).then(resp => {
        setTicketDetails(resp.data);
        nextStep();
      }).catch(err => {
        setFeedback("Feil brukernavn eller passord");
      })
    }

    return (
      <React.Fragment>
        <Container maxWidth="sm">
          <div className={classes.paper}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Logg inn for å se detaljer om din billett
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="groupname"
                label="Gruppenavn"
                name="groupname"
                type="text"
                autoFocus
                value={groupname}
                onChange={e => handleChange(0, e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Passord"
                type="password"
                id="password"
                value={password}
                onChange={e => handleChange(1, e.target.value)}
              />
              <Typography style={{color: 'red'}} align="center">
                {feedback}
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => handleLogin(e)}
              >
                Logg inn
              </Button>
            </form>
          </div>
        </Container>
      </React.Fragment>
    );
}