import { Box } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// Icons
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';
import React from 'react';
import Button from '../../../../components/material-kit-components/CustomButtons/Button';
import { useStyles } from './DownloadStyle';


export default function Download() {
    const classes = useStyles();

    const downloadExcel = () => {
        axios.get("https://skiogmagi.no:8443/buyers/hotel/1.xlsx");
        axios.get("https://skiogmagi.no:8443/buyers/hotel/2.xlsx");
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography className={classes.header} component="h1" variant="h4" align="center">
                        Last ned booking info
                    </Typography>
                    <Box display="flex" justifyContent="center">
                        <Button color="success" className={classes.button}
                            onClick={downloadExcel}>
                            <GetAppIcon/> Last ned
                        </Button>
                    </Box>
                </Paper>
            </main>
        </React.Fragment>
    );
}