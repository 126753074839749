import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from 'react-router-dom';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';

import App from './App';

import "./assets/material-kit-assets/scss/material-kit-react.scss";
// Fonts
import './assets/font/bebas/bebasneue.ttf';

import authReducer from './store/reducers/authReducer';

let hist = createBrowserHistory();

const rootReducer = combineReducers({
  auth: authReducer
});

// Logs the redux related state changes (Just for testing)
const loggerMiddleware = store => {
  return next => {
      return action => {
          const result = next(action);
          return result;
      }
  }
};

// Setting up broser debug support (see https://github.com/zalmoxisus/redux-devtools-extension)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create the redux store and adding middleware and such
const reduxStore = createStore(
    rootReducer, composeEnhancers(applyMiddleware(loggerMiddleware, thunk))
);

const app = (
    <Router history={hist}>
        <Provider store={reduxStore}>
            <App/>
        </Provider>
    </Router>
);

// Render
ReactDOM.render(app, document.getElementById("root"));