import React from 'react';
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns'

import ChangeTicketForm from './components/ChangeTicketForm';
import ChangeSummary from './components/ChangeSummary';

import { useStyles } from './ChangeStyle';
import TicketSearch from './components/TicketSearch';

const steps = ['Finn billett', 'Endre billett', 'Gjennomgå endring'];

export default function NewPurchase() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [orderId, setOrderid] = React.useState("");
    const [fullName, setFullName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [birth, setBirth] = React.useState(new Date());
    // Response
    const [response, setResponse] = React.useState("");

    const handleNext = () => {
        setActiveStep(activeStep + 1);
        if (activeStep === steps.length - 1) {
            handleSubmit();
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleChange = (input, e) => {

        switch (input) {
            case 0:
                setOrderid(e);
                break;

            case 2:
                setFullName(e);
                break;

            case 3:
                setEmail(e);
                break;

            case 4:
                setPhone(e);
                break;

            case 5:
                setBirth(e)
                break;

            default:
                break;
        }
    }

    const handleDateChange = date => {
        setBirth(date);
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <TicketSearch values={{ orderId, fullName, email, phone, birth }}
                    dateChange={handleDateChange} 
                    handleChange={handleChange}/>;

            case 1:
                return <ChangeTicketForm handleChange={handleChange} 
                    dateChange={handleDateChange}
                    values={{ orderId, fullName, email, phone, birth }} />;
            case 2:
                return <ChangeSummary
                    values={{ orderId, fullName, email, phone, birth }} />;
            default:
                throw new Error('Unknown step');
        }
    }

    const handleSubmit = () => {
        axios.post("https://skiogmagi.no:8443/updateBuyer", {
            orderId: orderId,
            name: fullName,
            email: email,
            phone: phone,
            birth: format(birth, "dd/MM/yyyy")
        }).then(resp => {
            setResponse("Endring av billett gikk smertefritt (fjo)");
        }).catch(err => {
            setResponse("Noe gikk galt! 😰 (" + err + ")");
        });
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h4" align="center">
                        Endre eksisterende kjøp
                    </Typography>
                    <Stepper activeStep={activeStep} className={classes.stepper}>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography variant="h5" gutterBottom>
                                    {response}
                                </Typography>
                            </React.Fragment>
                        ) : (
                                <React.Fragment>
                                    {getStepContent(activeStep)}
                                    <div className={classes.buttons}>
                                        {activeStep !== 0 && (
                                            <Button onClick={handleBack} className={classes.button}>
                                                Tilbake
                                        </Button>
                                        )}
                                        <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
                                            {activeStep === steps.length - 1 ? 'Fullfør' : 'Neste'}
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )}
                    </React.Fragment>
                </Paper>
            </main>
        </React.Fragment>
    );
}