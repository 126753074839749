import React, { useMemo, useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '../../../../components/material-kit-components/CustomButtons/Button';
// Icons
import PublishIcon from '@material-ui/icons/Publish';
// File dropzone (documentation: https://react-dropzone.js.org/)
import {useDropzone} from 'react-dropzone'
// import js style
import * as styles from './UploadStyle';

import { useStyles } from './UploadStyle';
import { Box } from '@material-ui/core';

import excelImg from '.././../../../assets/img/excel.png';

// css TODO Change to jss later
//import './DropzoneStyle.css';

export default function Upload() {
    const classes = useStyles();
    const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject } = useDropzone({
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        multiple: false,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const style = useMemo(() => ({
        ...styles.baseStyle,
        ...(isDragActive ? styles.activeStyle : {}),
        ...(isDragAccept ? styles.acceptStyle : {}),
        ...(isDragReject ? styles.rejectStyle : {})
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    const thumbs = files.map(file => (
        <div key={file.name}>
            <div style={styles.thumb}>
                <div style={styles.thumbInner}>
                    <img
                        src={excelImg}
                        style={styles.img}
                        alt="excel document logo"
                    />
                </div>
            </div>
            <p>{file.name}</p>
        </div>
    ));

    // Called when page is mounted
    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <React.Fragment>
            <CssBaseline/>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography className={classes.header} component="h1" variant="h4" align="center">
                        Opplast excel dokument
                    </Typography>
                    {/* File upload dropzone */}
                    <div className="container">
                        <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop excel file here, or click to select file</p>
                            <em>(Only *.xlsx files will be accepted)</em>
                        </div>
                    </div>
                    <aside style={styles.thumbsContainer}>
                        {thumbs}
                    </aside>
                    <Box display="flex" justifyContent="center">
                        <Button color="success" className={classes.button}>
                            <PublishIcon/> Opplast dokument
                        </Button>
                    </Box>
                </Paper>
            </main>
        </React.Fragment>
    );
}