//Components
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
// Icons
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import axios from "axios";
import React, { useEffect } from "react";

export default function InputRow(props) {
	const [orderIdFound, setOrderIdFound] = React.useState(false);
	// Import props
	const { handleOrderIdsChange, handleValidOrderIds, handleFormValidity, valids, selectedRoom } = props;

	let icon = "";
	if (orderIdFound) {
		icon = <CheckCircleOutlineIcon style={{ color: "#00b530" }} />;
	} else {
		icon = <HighlightOffIcon color="error" />;
	}

	const handleOnChange = orderId => {
		axios
			.get("https://skiogmagi.no:8443/orderId/" + orderId)
			.then(resp => {
				setOrderIdFound(true);
				{
					/* Buyer info */
				}
				{
					/* Name */
				}
				let name = resp.data.name;
				if (name === null) {
					name = "";
				}
				{
					/* Email */
				}
				let email = resp.data.email;
				if (email === null) {
					email = "";
				}
				{
					/* Phone */
				}
				let phone = resp.data.phone;
				if (phone === null) {
					phone = "";
				}
				{
					/* birth */
				}
				let birth = resp.data.birth;
				if (birth === null) {
					name = new Date();
				}
				// Compare input with the other inputs to see if there are any similarities
				for (let i = 0; i < props.value.length; i++) {
					if (i !== props.position) {
						if (props.value[props.position] === props.value[i]) {
							setOrderIdFound(false);
							handleValidOrderIds(props.position, false);
						} else {
							handleValidOrderIds(props.position, true);
						}
					}
				}
			})
			.catch(err => {
				setOrderIdFound(false);
			});
	};

	useEffect(() => {
		handleFormValidity(false);
	}, []);

	useEffect(() => {
		if (orderIdFound) {
			handleValidOrderIds(props.position, true);
			if (valids.length === selectedRoom) {
				let formIsValid = true;
				for (let i = 0; i < valids.length; i++) {
					if (valids[i] === false) {
						formIsValid = false;
					}
				}
				handleFormValidity(formIsValid);
			}
		} else {
			handleFormValidity(false);
		}
	}, [orderIdFound]);

	return (
		<React.Fragment>
			<Grid
				container
				spacing={0}
				style={{ position: "relative", marginLeft: 8, marginRight: 8, marginBottom: 8 }}
			>
				<Grid item xs={10} sm={11} xl={11}>
					<TextField
						required
						error={!orderIdFound}
						id={props.id.toString()}
						key={props.keyId}
						label={props.label}
						type="number"
						fullWidth
						onChange={e => {
							handleValidOrderIds(props.position, false);
							// This method must be first to update the value first on
							handleOrderIdsChange(props.position, e.target.value);
							// This method must be second as it has to use the latest updated value from above
							handleOnChange(props.value[props.position]);
						}}
						defaultValue={props.value[props.position]}
					/>
				</Grid>
				<Grid item xs={2} sm={1} xl={1} style={{ bottom: 0, right: 0, position: "absolute" }}>
					{icon}
				</Grid>
			</Grid>
		</React.Fragment>
	);
}
