import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import axios from 'axios';
import React from "react";

class BookingReview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            password: ""
        }
    }

    componentDidMount() {
        const { values } = this.props;
        axios.post("https://skiogmagi.no:8443/finishBooking", {
            hotelId: values.selectedHotel.id,
            roomSize: values.selectedRoom,
            username: values.username,
            responsibleOrderId: values.orderIds[0],
            orderIds: values.orderIds,
            comment: values.comment
        }).then(resp => {
            this.setState({ 
                password: resp.data,
                success: true
            })
        }).catch(err => {
            this.setState({ success: false })
        });
    }

	render() {
        const { values } = this.props;
		return (
			<React.Fragment>
				<Typography
					component="h1"
					variant="h4"
					align="center"
					color="textPrimary"
					gutterBottom
					style={{ marginBottom: 30 }}
				>
					Billett detaljer
				</Typography>
				<Grid item container direction="column" xs={12}>
                    {(this.state.success === true) ? (
                        <Grid container>
                            {/* Hotel */}
                            <Grid item xs={6}>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    style={{ fontWeight: "bold" }}
                                    gutterBottom
                                >
                                    Hotell:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography component="h1" variant="h6" gutterBottom>
                                    {values.selectedHotel.name}
                                </Typography>
                            </Grid>
                            {/* Rom */}
                            <Grid item xs={6}>
                                <Typography style={{ fontWeight: "bold" }} gutterBottom>
                                    Rom størrelse:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom>{values.selectedRoom} mannsrom</Typography>
                            </Grid>
                            {/* Authentication information */}
                            <Grid item xs={12}>
                                <Typography style={{marginTop: 15, marginBottom: 15}} align="center">
                                    Viktig å huske! Brukernavn og passord brukes til å se informasjon 
                                    om din bestilling senere i "SE BILLETT tabben". Har du noen spørsmål, 
                                    send oss en mail på skiogmagi@astudent.no :D
                                </Typography>
                            </Grid>
                            {/* Username */}
                            <Grid item xs={12}>
                                <Typography align="center"
                                    component="h1"
                                    variant="h6"
                                    style={{ fontWeight: "bold" }}
                                    gutterBottom>
                                    Brukernavn: {values.username}
                                </Typography>
                            </Grid>
                            {/* Password */}
                            <Grid item xs={12}>
                                <Typography align="center"
                                    component="h1"
                                    variant="h6"
                                    style={{ fontWeight: "bold" }}
                                    gutterBottom>
                                    Passord: {this.state.password}
                                </Typography>
                            </Grid>
                            {/* Residents */}
                            {values.orderIds.map(orderId => (
                                (orderId === values.orderIds[0]) ? (
                                    <Grid container style={{marginTop: 15}}>
                                        {/* Person responsibility*/}
                                        <Grid item xs={12}>
                                            <Typography style={{ fontWeight: "bold" }} gutterBottom>
                                                Romansvarlig
                                            </Typography>
                                        </Grid>
                                        {/* Order ID */}
                                        <Grid item xs={6}>
                                            <Typography gutterBottom>Ordre ID:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom>#{orderId}</Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container style={{marginTop: 15}}>
                                        {/* Person responsibility*/}
                                        <Grid item xs={12}>
                                            <Typography style={{ fontWeight: "bold" }} gutterBottom>
                                                Annen
                                            </Typography>
                                        </Grid>
                                        {/* Order ID */}
                                        <Grid item xs={6}>
                                            <Typography gutterBottom>Ordre ID:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom>#{orderId}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            ))}
                            {/* Residents */}
                            <Grid item xs={12}>
                                <Typography style={{ fontWeight: "bold", marginTop: 15 }} gutterBottom>
                                    Kommentar
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>{values.comment}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Typography style={{marginTop: 15, marginBottom: 15}} align="center">
                                    Noe gikk galt. Ta kontakt med noen i ÅSS 
                                    (Vi har kontor nederst til venstre på B-siden i hovedbygget)
                                </Typography>
                        </Grid>
                    )}
				</Grid>
			</React.Fragment>
		);
	}
}

export default BookingReview;
