import React from 'react';
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import NewRoomForm from './components/NewRoomForm';
import NewRoomReview from './components/NewRoomReview';

import { useStyles } from './NewRoomStyle';

const steps = ['Legg til nytt rom', 'Gjennomgå rom informasjon'];

export default function NewRoom() {
    const classes = useStyles();
    // States
    const [activeStep, setActiveStep] = React.useState(0);
    const [hotel, setHotel] = React.useState("");
    const [maxResidentalsAmount, setMaxResidentalsAmount] = React.useState(0);
    const [totalRooms, setTotalRooms] = React.useState(0);
    // Response
    const [response, setResponse] = React.useState("");

    const handleNext = () => {
        setActiveStep(activeStep + 1);
        if (activeStep === steps.length - 1) {
            handleSubmit();
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleChange = (input, e) => {
        if (input === 0) {
            setHotel(e);
        } else if (input === 1) {
            setMaxResidentalsAmount(e);
        } else if (input === 2) {
            setTotalRooms(e);
        }
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <NewRoomForm handleChange={handleChange} values={{ hotel, maxResidentalsAmount, totalRooms }}/>;
            case 1:
                return <NewRoomReview values={{ hotel, maxResidentalsAmount, totalRooms }} />;
            default:
                throw new Error('Unknown step');
        }
    }

    const handleSubmit = () => {
        axios.post("https://skiogmagi.no:8443/newRoom", {
            hotelId: hotel.id,
            maxResidentalsAmount: maxResidentalsAmount,
            totalRooms: totalRooms
        }).then(resp => {
            setResponse("Registrering av nytt rom vellykket ❤️");
        }).catch(err => {
            setResponse("Noe gikk galt! 😰 (" + err + ")");
        });
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h4" align="center">
                        Legg til rom
                    </Typography>
                    <Stepper activeStep={activeStep} className={classes.stepper}>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography variant="h5" gutterBottom>
                                    {response}
                                </Typography>
                            </React.Fragment>
                        ) : (
                                <React.Fragment>
                                    {getStepContent(activeStep)}
                                    <div className={classes.buttons}>
                                        {activeStep !== 0 && (
                                            <Button onClick={handleBack} className={classes.button}>
                                                Tilbake
                                        </Button>
                                        )}
                                        <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
                                            {activeStep === steps.length - 1 ? 'Fullfør' : 'Neste'}
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )}
                    </React.Fragment>
                </Paper>
            </main>
        </React.Fragment>
    );
}