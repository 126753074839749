import Divider from "@material-ui/core/Divider";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PersonIcon from "@material-ui/icons/Person";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import React from "react";
import Card from "../../../../components/material-kit-components/Card/Card";
import CardBody from "../../../../components/material-kit-components/Card/CardBody.js";
import CardHeader from "../../../../components/material-kit-components/Card/CardHeader.js";
import { useStyles } from "./BookingsStyle";
import BookingRow from "./components/BookingRow";
import { Button } from "@material-ui/core";

export default function Bookings() {
	const classes = useStyles();
	const [buyers, setBuyers] = React.useState([]);
	const [comment, setComment] = React.useState("");
	const [commentInput, setCommentInput] = React.useState("");

	const doSearch = search => {
		if (search !== "") {
			axios
				.get("https://skiogmagi.no:8443/getBuyersAndInfo/" + search)
				.then(resp => {
					setBuyers(resp.data);
				})
				.catch(() => {
					setBuyers({ 0: { orderId: "Couldn't fetch buyers..." } });
				});
		}
	};

	const searchComments = () => {
		if (commentInput !== "") {
			axios
				.get("https://skiogmagi.no:8443/getRoomComment/" + commentInput)
				.then(response => {
					setComment(response.data);
				})
				.catch(() => {
					setComment("Ordre id finnes ikke");
				});
		}
	};

	return (
		<React.Fragment>
			{/* Main Section */}
			<Card>
				<CardHeader color="skiOgMagi">
					<Paper className={classes.root}>
						<PersonIcon className={classes.icon} />
						<InputBase
							className={classes.input}
							placeholder="Søk deltaker"
							inputProps={{ "aria-label": "search participant" }}
							onChange={e => doSearch(e.target.value)}
						/>
						<Divider className={classes.divider} orientation="vertical" />
					</Paper>
				</CardHeader>
				<CardBody>
					<Table className={classes.table} stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>Ordre ID</TableCell>
								<TableCell>Navn</TableCell>
								<TableCell>Epost</TableCell>
								<TableCell>Mobil</TableCell>
								<TableCell>Hotell</TableCell>
								<TableCell>Rom Størrelse</TableCell>
								<TableCell>Ansvarlig</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{buyers.map(row => (
								<BookingRow
									key={row.buyerId}
									orderId={row.orderId}
									name={row.buyerName}
									email={row.email}
									phone={row.phone}
									hotel={row.hotelName}
									type={row.maxAmount}
									responsible={row.responsibleOrderId}
								/>
							))}
						</TableBody>
					</Table>
				</CardBody>
			</Card>
			{/* Room comment section */}
			<Card style={{ marginTop: 300 }}>
				<CardHeader color="skiOgMagi">
					<Paper className={classes.root}>
						<SearchIcon className={classes.icon} />
						<InputBase
							className={classes.input}
							placeholder="Søk ordreid"
							inputProps={{ "aria-label": "search orderid" }}
							onChange={e => setCommentInput(e.target.value)}
						/>
						<Divider className={classes.divider} orientation="vertical" />
						<Button color="primary" onClick={() => searchComments()}>
							Søk
						</Button>
					</Paper>
				</CardHeader>
				<CardBody>{comment}</CardBody>
			</Card>
		</React.Fragment>
	);
}
