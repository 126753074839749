import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
// Resident component
import ResidentDetail from "./ResidentDetail";


class TicketSummary extends React.Component {

    constructor() {
		super();
    }

	render() {
		const { values } = this.props;
		return (
			<React.Fragment>
				<Typography
					component="h1"
					variant="h4"
					align="center"
					color="textPrimary"
					gutterBottom
					style={{ marginBottom: 20 }}
				>
					Billett detaljer
				</Typography>
				<Grid item container direction="column" xs={12}>
					<Grid container>
						{/* Hotel */}
						<Grid item xs={6}>
							<Typography
								component="h1"
								variant="h6"
								style={{ fontWeight: "bold" }}
								gutterBottom
							>
								Hotell:
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography component="h1" variant="h6" gutterBottom>
								{values.ticketDetails[0].hotelName}
							</Typography>
						</Grid>
						{/* Rom */}
						<Grid item xs={6}>
							<Typography style={{ fontWeight: "bold" }} gutterBottom>
								Rom størrelse:
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography gutterBottom>{values.ticketDetails[0].maxAmount} mannsrom</Typography>
						</Grid>
						{/* Residents */}
						{values.ticketDetails.map(ticket => (
							(ticket.orderId === ticket.responsibleOrderId) ? (
								<ResidentDetail
									responsibility={"Romansvarlig"}
									orderId={ticket.orderId}
									name={ticket.buyerName}
									email={ticket.email}
									phone={ticket.phone}
									birth={ticket.birth}
								/>
							) : (
								<ResidentDetail
									responsibility={"Annen"}
									orderId={ticket.orderId}
									name={ticket.buyerName}
									email={ticket.email}
									phone={ticket.phone}
									birth={ticket.birth}
								/>
							)
						))}
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
}

export default TicketSummary;
