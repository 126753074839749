import { makeStyles } from '@material-ui/core/styles';

import Logo from '../../assets/img/background.jpg';

export const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            background: `url(${Logo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            OBackgroundSize: 'cover',
            backgroundPosition: 'center',
            MozBackgroundSize: 'cover',
        }
    }
}));