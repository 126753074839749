import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import React, { useEffect } from "react";
import { useStyles } from "./BookingStyle";
import BookingForm from "./components/BookingForm";
import BookingReview from "./components/BookingReview";


export default function Booking() {
    const classes = useStyles();
    // Handler states
    const [activeStep, setActiveStep] = React.useState(0);
    const [response, setResponse] = React.useState("");
    const [formValid, setFormValid] = React.useState(false);
    // Collection states
    const [buyers, setBuyers] = React.useState({});
    // Values to send states
    const [selectedHotel, setSelectedHotel] = React.useState(-1);
    const [selectedRoom, setSelectedRoom] = React.useState(-1);
    const [orderIds, setOrderIds] = React.useState([]);
    const [validOrderIds, setValidOrderIds] = React.useState([]);
    // Text values
    const [username, setUsername] = React.useState("");
    const [comment, setComment] = React.useState("");
    // "Touched fields" states

    useEffect(() => {
        if (validOrderIds.length >= 2) {
            let formIsValid = true;
            for (let i = 0; i < validOrderIds.length; i++) {
                if (validOrderIds[i] === false) {
                    formIsValid = false;
                }
            }
            setFormValid(formIsValid);
        }
    }, [orderIds]);

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleChange = (input, e) => {
        
        switch (input) {
            case 0:
                setSelectedHotel(e);
                break;

            case 1:
                setSelectedRoom(e);
                break;

            case 2:
                setUsername(e);
                break;

            case 3: 
                setComment(e);
                break;
        }

    };

    const handleOrderIdsChange = (input, e) => {
        let d = orderIds;
        d[input] = e;
        setOrderIds(d);
    };

    const handleValidOrderIds = (input, value) => {
        let val = validOrderIds;
        val[input] = value;
        setValidOrderIds(val);
    }

    const getStepContent = () => {
        switch (activeStep) {
           case 0:
                return <BookingForm handleChange={handleChange}
                    handleOrderIdsChange={handleOrderIdsChange}
                    handleValidOrderIds={handleValidOrderIds}
                    handleNext={handleNext}
                    values={{selectedHotel, selectedRoom, orderIds, validOrderIds, formValid}}/>;

            case 1:
                return <BookingReview values={{selectedHotel, selectedRoom, orderIds, username, comment}}/>;

            default:
                throw new Error('Unknown step');
        }
    };

    const handleSubmit = () => {

    };

    return (
        <React.Fragment>
            <CssBaseline/>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <React.Fragment>
                        {getStepContent(activeStep)}
                    </React.Fragment>
                </Paper>
            </main>
        </React.Fragment>
    );
}