import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// Format date
import { format } from "date-fns";
import React from "react";

export default function ResidentDetail(props) {
    return (
      <React.Fragment>
        <Grid container style={{marginTop: 15}}>
          {/* Person responsibility*/}
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold" }} gutterBottom>
              {props.responsibility}
            </Typography>
          </Grid>
          {/* Order ID */}
          <Grid item xs={6}>
            <Typography gutterBottom>Ordre ID:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom>#{props.orderId}</Typography>
          </Grid>
          {/* Full name of the person with the order*/}
          <Grid item xs={6}>
            <Typography gutterBottom>Fullt Navn:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom>{props.name}</Typography>
          </Grid>
          {/* Email of the person with the order*/}
          <Grid item xs={6}>
            <Typography gutterBottom>Epost:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom>{props.email}</Typography>
          </Grid>
          {/* Persons' phone number */}
          <Grid item xs={6}>
            <Typography gutterBottom>Mobil:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom>{props.phone}</Typography>
          </Grid>
          {/* Persons' birth date*/}
          <Grid item xs={6}>
            <Typography gutterBottom>Fødselsdato:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom>
              {format(props.birth, "dd/MM/yyyy")}
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
}