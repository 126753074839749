import React from "react";
import Card from "../../components/material-kit-components/Card/Card";
import CardBody from "../material-kit-components/Card/CardBody";
import CardHeader from "../material-kit-components/Card/CardHeader";

export default function ProgramCard(props) {
	return (
		<Card>
			<CardHeader style={{ textAlign: "center" }} color="skiOgMagi">
				{props.day}
			</CardHeader>
			<CardBody>{props.children}</CardBody>
		</Card>
	);
}
