import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
import ProgramCard from "../../../components/ProgramCard/ProgramCard";
import { useStyles } from "./ProgramStyle";

export default function Program() {
	const classes = useStyles();

	return (
		<div>
			<Container maxWidth="xl">
				<Grid className={classes.root} container spacing={3} justify="center">
					<Grid item xs={12} md={6} lg={3}>
						<ProgramCard day="TORSDAG">
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Avgang fra Banken</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>12.00 - 13.30</h5>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Middag (165,-)</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>18.00 - 19.30</h5>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Trubadur Tico</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>22.00</h5>
								</Grid>
							</Grid>
						</ProgramCard>
					</Grid>
					<Grid item xs={12} md={6} lg={3}>
						<ProgramCard day="FREDAG">
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Frokost</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>08.00 - 11.00</h5>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575", marginBottom: 0 }}>
										Gaperday i bakken <br />
									</h5>
									<p style={{ color: "#757575" }}>(husk å kle deg ut)</p>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>10.00 - 16.00</h5>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Konkurranser ved campen</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>12.00 - 14.00</h5>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Start griller</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>12.00 - 14.00</h5>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Afterski i Vangslia</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>16.00 - 18.00</h5>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Middag (165,-)</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>18.00 - 19.30</h5>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Soppgirobygget</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>23.00</h5>
								</Grid>
							</Grid>
						</ProgramCard>
					</Grid>
					<Grid item xs={12} md={6} lg={3}>
						<ProgramCard day="LØRDAG">
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Frokost</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>08.00 - 11.00</h5>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Aktiviteter med Redbull</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>12.00 - 16.00</h5>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Studentparlamentet griller</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>12.00 - 14.00</h5>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Afterski i Vangslia</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>16.00 - 18.00</h5>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Middag (165,-)</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>18.00 - 19.30</h5>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Temafest - Magisk aften</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>22.00</h5>
								</Grid>
							</Grid>
						</ProgramCard>
					</Grid>
					<Grid item xs={12} md={6} lg={3}>
						<ProgramCard day="SØNDAG">
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Frokost</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>08.00 - 11.00</h5>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Utsjekk</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>12.00</h5>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={7}>
									<h5 style={{ color: "#757575" }}>Hjemreise</h5>
								</Grid>
								<Grid item xs={5} style={{ textAlign: "right" }}>
									<h5 style={{ color: "#757575" }}>12.30</h5>
								</Grid>
							</Grid>
						</ProgramCard>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}
