import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

class NewRoomReview extends React.Component {
    render() {
        const { values } = this.props;
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Sammendrag av opprettelse av nytt rom
                </Typography>
                <Grid item container direction="column" xs={12}>
                    <Grid container>
                        {/* Hotel that the room should be under */}
                        <Grid item xs={6}>
                            <Typography gutterBottom>
                                Hotell:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom>{values.hotel.name}</Typography>
                        </Grid>
                        {/* Max Amount */}
                        <Grid item xs={6}>
                            <Typography gutterBottom>
                                Maks Antall personer i rommet:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom>{values.maxResidentalsAmount} mannsrom</Typography>
                        </Grid>
                        {/* Room letter*/}
                        <Grid item xs={6}>
                            <Typography gutterBottom>
                                Antall rom:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom>{values.totalRooms} antall rom</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default NewRoomReview;