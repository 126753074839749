import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Swiper from "react-id-swiper";
import { useStyles } from "./MerchStyle";



export default function Merch() {
    const classes = useStyles();

    const params = {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true
        },
        /*autoplay: {
            delay: 2500,
            disableOnInteraction: false
        },*/
        loop: true,
    };

    return (
        <React.Fragment>
            <CssBaseline/>
            <div className={classes.layout}>
                <Paper className={classes.paper}>
                    <div className={classes.heroContent}>
                        <Container maxWidth="md">
                            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                                MERCH
                            </Typography>
                            <Typography variant="h5" align="center" color="textSecondary" paragraph>
                                Kjøp av merch gjøres via Vipps ved å søke opp Ski og Magi eller bruke koden #129531
                            </Typography>
                        </Container>
                    </div>
                    <Container className={classes.cardGrid} maxWidth="lg">
                        {/* End hero unit */}
                        <Grid container spacing={4}>
                            <Grid item key={1} xs={12} sm={6} md={4}>
                                <Card className={classes.card}>
                                    <CardMedia>
                                        <Swiper {...params}>
                                            <div><img className={classes.cardImage} src={require("../../../assets/img/merch/sunglasses/sunglasses_med_2.jpg")} alt="To med solbriller"/></div>
                                            <div><img className={classes.cardImage} src={require("../../../assets/img/merch/sunglasses/sunglasses_1.jpg")} alt="Solbriller"/></div>
                                            <div><img className={classes.cardImage}src={require("../../../assets/img/merch/sunglasses/sunglasses_2.jpg")} alt="Solbriller"/></div>
                                            <div><img className={classes.cardImage} src={require("../../../assets/img/merch/sunglasses/sunglasses.jpg")} alt="Solbriller"/></div>
                                        </Swiper>
                                    </CardMedia>
                                    <CardContent className={classes.cardContent}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Solbriller
                                        </Typography>
                                        <Typography>
                                            Gruer du deg til det skarpe lyset dagen-derpå? Frykt ikke!
                                            Med disse fete brillene har du god beskyttelse
                                            for både skarpt lys og fremmedlegeme i øyet.
                                        </Typography>
                                        <Typography style={{fontSize: 20}}>
                                            <b>Pris 49,-</b>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item key={2} xs={12} sm={6} md={4}>
                                <Card className={classes.card}>
                                    <CardMedia>
                                        <Swiper {...params}>
                                            <div><img className={classes.cardImage} src={require("../../../assets/img/merch/lue/lue_med_2.jpg")} alt="To med lue"/></div>
                                            <div><img className={classes.cardImage} src={require("../../../assets/img/merch/lue/lue_1.jpg")} alt="Lue"/></div>
                                            <div><img className={classes.cardImage} src={require("../../../assets/img/merch/lue/lue_2.jpg")} alt="Lue"/></div>
                                            <div><img className={classes.cardImage} src={require("../../../assets/img/merch/lue/lue_3.jpg")} alt="Lue"/></div>
                                            <div><img className={classes.cardImage} src={require("../../../assets/img/merch/lue/lue.jpg")} alt="Lue"/></div>
                                        </Swiper>
                                    </CardMedia>
                                    <CardContent className={classes.cardContent}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Lue
                                        </Typography>
                                        <Typography>
                                            Med denne luen trenger du ikke lenger å tenke på om du passer inn,
                                            eller om du er kul nok.
                                            Med denne på hodet har 149% større sjanse på å få deg noe på SKI & MAGI.
                                        </Typography>
                                        <Typography style={{fontSize: 20}}>
                                            <b>Pris 99,-</b>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item key={3} xs={12} sm={6} md={4}>
                                <Card className={classes.card}>
                                    <CardMedia>
                                        <Swiper {...params}>
                                            <div><img className={classes.cardImage} src={require("../../../assets/img/merch/fanny/fanny_med_2.jpg")} alt="To med fanny pack"/></div>
                                            <div><img className={classes.cardImage} src={require("../../../assets/img/merch/fanny/fanny_1.jpg")} alt="Fanny pack"/></div>
                                            <div><img className={classes.cardImage} src={require("../../../assets/img/merch/fanny/fanny_2.jpg")} alt="Fanny pack"/></div>
                                          <div><img className={classes.cardImage} src={require("../../../assets/img/merch/fanny/fanny.jpg")} alt="Fanny pack"/></div>
                                        </Swiper>
                                    </CardMedia>
                                    <CardContent className={classes.cardContent}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Fannypack
                                        </Typography>
                                        <Typography>
                                            Pakker du fremdeles i sekk og får daglig buksevann?
                                            Ha ditt one-night-stand-kit i denne lekre saken av en pung!
                                        </Typography>
                                        <Typography style={{fontSize: 20}}>
                                            <b>Pris 49,-</b>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </div>
        </React.Fragment>
    );
}