import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    layout: {
      width: 'auto',
      height: '100%',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 1200,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {

    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    '@media (min-width: 1000px)': {
      card: {
        width: '100%',
        maxWidth: '340px'
      }
    },
    cardImage: {
      maxHeight: '227px',
      minH: 'auto',
      maxWidth: '340px',
      width: '100%'
    },
    cardContent: {
      flexGrow: 1,
    },
  }));