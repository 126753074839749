// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/font/bebas-neue/BebasNeueBold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face {\n    font-family: 'BebasNeue';\n    src: local('BebasNeue'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), format('truetype');\n}\n\n.footer-header {\n    font-family: 'BebasNeue';\n    font-weight: bold;\n}", ""]);
// Exports
module.exports = exports;
