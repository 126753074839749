import React from 'react';
import { Route } from 'react-router-dom';

import Navbar from './../../components/Navbar/Navbar';

import { useStyles } from './BackgroundRouteStyle';

import './BackgroundRouteCSS.css';

function DefaultLayout({ children }) {
    const classes = useStyles();
    return (
        <div>
            <Navbar />
            {children}
        </div>
    );
};

const DefaultRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <DefaultLayout>
                <Component {...matchProps} />
            </DefaultLayout>
        )} />
    );
};

export default DefaultRoute;