import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { format } from 'date-fns';
import React from 'react';
import NewPurchaseForm from './components/NewPurchaseForm';
import PurchaseReview from './components/PurchaseReview';
import { useStyles } from './NewPurchaseStyle';



const steps = ['Registrer et kjøp', 'Gjennomgå kjøp'];

export default function NewPurchase() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [orderId, setOrderid] = React.useState("");
    const [fullName, setFullName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [birth, setBirth] = React.useState(new Date());
    // Response
    const [response, setResponse] = React.useState("");

    const handleNext = () => {
        setActiveStep(activeStep + 1);
        if (activeStep === steps.length - 1) {
            handleSubmit();
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleChange = (input, e) => {

        switch (input) {
            case 0:
                setOrderid(e);
                break;

            case 2: 
                setFullName(e);
                break;

            case 3:
                setEmail(e);
                break;

            case 4:
                setPhone(e);
                break;

            default:
                break;
        }
    }

    const handleDateChange = date => {
        setBirth(date);
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <NewPurchaseForm handleChange={handleChange} dateChange={handleDateChange} 
                            values={{orderId, fullName, email, phone, birth}}/>;
            case 1:
                return <PurchaseReview 
                            values={{orderId, fullName, email, phone, birth}}/>;
            default:
                throw new Error('Unknown step');
        }
    }

    const handleSubmit = () => {
        axios.post("https://skiogmagi.no:8443/newBuyer", {
            orderId: orderId,
            name: fullName,
            email: email,
            phone: phone,
            birth: format(birth, "dd/MM/yyyy")
        }).then(resp => {
            setResponse("Registrering av nytt kjøp vellykket ❤️");
        }).catch(err => {
            setResponse("Noe gikk galt! 😰 (" + err + ")");
        });
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h4" align="center">
                        Registrer nytt kjøp
                    </Typography>
                    <Stepper activeStep={activeStep} className={classes.stepper}>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography variant="h5" gutterBottom>
                                    {response}
                                </Typography>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {getStepContent(activeStep)}
                                <div className={classes.buttons}>
                                    {activeStep !== 0 && (
                                        <Button onClick={handleBack} className={classes.button}>
                                            Tilbake
                                        </Button>
                                    )}
                                    <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
                                        {activeStep === steps.length - 1 ? 'Fullfør' : 'Neste'}
                                    </Button>
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </Paper>
            </main>
        </React.Fragment>
    );
}