import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container, Grid } from '@material-ui/core';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import AdminNavbar from './../../components/AdminNavbar/AdminNavbar';

import { useStyles } from './AdminRouteStyle';

function AdminLayout({ children }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AdminNavbar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {children}
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    );
};

const AdminRoute = ({ component: Component, isAuthenticated, ...rest }) => {
    return (
        <Route {...rest} render={
            (props) => (isAuthenticated === true)
                ? <AdminLayout><Component { ...props } /></AdminLayout>
                : <Redirect to={{
                    pathname: "/",
                    state: {from: props.location}
                }}/>
            }
        />
    );
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

export default connect(mapStateToProps)(AdminRoute);