import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Bookings from "views/containers/Admin/Bookings/Bookings";
import Download from "views/containers/Admin/Download/Download";
import NewHotel from "views/containers/Admin/NewHotel/NewHotel";
import NewPurchase from "views/containers/Admin/NewPurchase/NewPurchase";
import NewRoom from "views/containers/Admin/NewRoom/NewRoom";
import Upload from "views/containers/Admin/Upload/Upload";
import Booking from "views/containers/Booking/Booking";
import Merch from "views/containers/Merch/Merch";
import SeeTicket from "views/containers/SeeTicket/SeeTicket";
import Logout from "./components/Util/Logout";
import AdminRoute from "./routes/Admin/AdminRoute";
import BackgroundRoute from "./routes/BackgroundRoute/BackgroundRoute";
import * as actions from "./store/actions/actionBundler";
import Change from "./views/containers/Admin/Change/Change";
import Dashboard from "./views/containers/Admin/Dashboard/Dashboard";
import ChangeTicket from "./views/containers/ChangeTicket/ChangeTicket";
import Home from "./views/containers/Home/Home";
import Login from "./views/containers/Login/Login";
import Program from "./views/containers/Program/Program";

/**
 * Create App as a functional component so we can use react hooks (if needed)
 */
class App extends Component {
	// TODO THIS NEEDS TO BE CHANGED!!! CANT USE COMPONENT_WILL_MOUNT
	componentWillMount() {
		this.props.onTryAutoSignIn();
	}

	render() {
		let router = null;
		if (this.props.isAuthenticated) {
			router = (
				<React.Fragment>
					<AdminRoute path="/registrer-kjop" component={NewPurchase} />
					<AdminRoute path="/add-hotel" component={NewHotel} />
					<AdminRoute path="/add-room" component={NewRoom} />
					<AdminRoute path="/download" component={Download} />
					<AdminRoute path="/bookings" component={Bookings} />
					<AdminRoute path="/upload" component={Upload} />
					<AdminRoute path="/change" component={Change} />
					<AdminRoute path="/admin" component={Dashboard} />
					<Route path="/logout" component={Logout} />
					{/* <BackgroundRoute path="/change-ticket" component={ChangeTicket} />
					<BackgroundRoute path="/see-ticket" component={SeeTicket} />
					<BackgroundRoute path="/booking" component={Booking} /> */}
					<BackgroundRoute path="/program" component={Program} />
					<BackgroundRoute path="/merch" component={Merch} />
					<BackgroundRoute path="/" exact component={Home} />
				</React.Fragment>
			);
		} else {
			router = (
				<React.Fragment>
					<Route path="/admin" component={Login} />
					{/* <BackgroundRoute path="/change-ticket" component={ChangeTicket} />
					<BackgroundRoute path="/see-ticket" component={SeeTicket} />
					<BackgroundRoute path="/booking" component={Booking} /> */}
					<BackgroundRoute path="/program" component={Program} />
					<BackgroundRoute path="/merch" component={Merch} />
					<BackgroundRoute path="/" exact component={Home} />
				</React.Fragment>
			);
		}

		return (
			<div>
				<Switch>{router}</Switch>
			</div>
		);
	}
}

// Access user loading state (on login)
const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.token !== null
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onTryAutoSignIn: () => dispatch(actions.authCheckState())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(App);
