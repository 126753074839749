import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';

class ChangeTicketForm extends React.Component {
    render() {
        const { values, handleChange, dateChange } = this.props;
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Kjøps informasjon
                </Typography>
                <Grid container spacing={3}>
                    {/* Order ID */}
                    <Grid item xs={12}>
                        <TextField required={true} disabled
                            id="orderId" name="orderId"
                            label="Ordre ID" type="number"
                            fullWidth onChange={(e) => handleChange(0, e.target.value)}
                            defaultValue={values.orderId} />
                    </Grid>
                    {/* Name */}
                    <Grid item xs={12}>
                        <TextField required={true}
                            id="fullName" name="fullName"
                            label="Fullt Navn" type="text"
                            fullWidth onChange={(e) => handleChange(2, e.target.value)}
                            defaultValue={values.fullName} />
                    </Grid>
                    {/* Email */}
                    <Grid item xs={12}>
                        <TextField required={true}
                            id="email" name="email"
                            label="Epost" type="text"
                            fullWidth onChange={(e) => handleChange(3, e.target.value)}
                            defaultValue={values.email} />
                    </Grid>
                    {/* Phone */}
                    <Grid item xs={12}>
                        <TextField required={true}
                            id="phone" name="phone"
                            label="Mobil" type="text"
                            fullWidth onChange={(e) => handleChange(4, e.target.value)}
                            defaultValue={values.phone} />
                    </Grid>
                    {/* Birth (Date) */}
                    <Grid item xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                required={true} variant="inline"
                                format="dd/MM/yyyy" margin="normal"
                                id="birth" name="birth"
                                label="Fødselsdato"
                                fullWidth onChange={dateChange}
                                value={values.birth}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }} />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default ChangeTicketForm;