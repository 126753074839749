import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import React from "react";
import InputRow from "./InputRow";

class BookingForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hotelTouched: false,
			roomTouched: false,
			hotels: [],
			rooms: [],
			selectedRoomCapacity: 0,
			checked: false,
			username: "",
			comment: "",
			formValid: false,
			usernameFilled: false
		};
		this.handleFormValidity = this.handleFormValidity.bind(this);
	}

	componentDidMount() {
		axios
			.get("https://skiogmagi.no:8443/getAllHotels")
			.then(response => {
				this.setState({ hotels: response.data });
			})
			.catch(error => {});
	}

	getAllRoomsByHotelId(hotelId) {
		axios
			.get("https://skiogmagi.no:8443/getAllAvailableRoomsByHotelId/" + hotelId.id)
			.then(response => {
				this.setState({ rooms: response.data });
			})
			.catch(error => {});
	}

	getSelectedRoomDetails(roomId) {
		let rooms = this.state.rooms;
		rooms.forEach(room => {
			if (room.roomId === roomId) {
				this.setState({ selectedRoomCapacity: room.maxAmount });
			}
		});
	}

	inputChangeHandler(input, value) {
		switch (input) {
			case 0:
				if (this.state.username.length < 25) {
					let usernameState = this.state.username;
					usernameState = value;
					this.setState({ username: usernameState });
					if (usernameState.length > 1) {
						this.setState({ usernameFilled: true });
					} else {
						this.setState({ usernameFilled: false });
					}
				}
				break;

			case 1:
				if (this.state.comment.length < 200) {
					let commentState = this.state.comment;
					commentState = value;
					this.setState({ comment: commentState });
				} else {
				}
				break;

			default:
				break;
		}
	}

	handleFormValidity(value) {
		let oldState = this.state.formValid;
		oldState = value;
		this.setState({ formValid: oldState });
	}

	render() {
		const { handleChange, values, handleOrderIdsChange, handleValidOrderIds, handleNext } = this.props;
		return (
			<React.Fragment>
				<Typography component="h1" variant="h4" align="center" style={{ marginBottom: 30 }}>
					Fullfør booking!
				</Typography>
				<Grid container spacing={3}>
					{/* Hotel selection */}
					<Grid item xs={12}>
						<TextField
							required
							id="hotel"
							name="hotel"
							select
							label="Hotell"
							fullWidth
							value={values.selectedHotel}
							onChange={e => {
								handleChange(0, e.target.value);
								this.getAllRoomsByHotelId(e.target.value);
								this.setState({ hotelTouched: true });
							}}
						>
							{this.state.hotels.map(hotel => (
								<MenuItem key={hotel} value={hotel}>
									{hotel.name}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					{/* Room selection */}
					<Grid item xs={12}>
						<TextField
							required
							id="room"
							name="room"
							select
							label="Rom"
							fullWidth
							value={values.selectedRoom}
							disabled={!this.state.hotelTouched}
							onChange={e => {
								handleChange(1, e.target.value);
								this.setState({
									roomTouched: true,
									formValid: false
								});
							}}
						>
							{this.state.rooms.map(room => (
								<MenuItem key={room} value={room}>
									{room} manns rom
								</MenuItem>
							))}
						</TextField>
					</Grid>
					{/* Input fields */}
					{values.selectedRoom === 3 ? (
						<React.Fragment>
							<InputRow
								keyId={0}
								id={0}
								label={"Ordre ID Romansvarlig"}
								position={0}
								value={values.orderIds}
								handleOrderIdsChange={handleOrderIdsChange}
								valids={values.validOrderIds}
								handleValidOrderIds={handleValidOrderIds}
								handleFormValidity={this.handleFormValidity}
								selectedRoom={values.selectedRoom}
							/>
							<InputRow
								keyId={1}
								id={1}
								label={"Ordre ID"}
								position={1}
								value={values.orderIds}
								handleOrderIdsChange={handleOrderIdsChange}
								valids={values.validOrderIds}
								handleValidOrderIds={handleValidOrderIds}
								handleFormValidity={this.handleFormValidity}
								selectedRoom={values.selectedRoom}
							/>
							<InputRow
								keyId={2}
								id={2}
								label={"Ordre ID"}
								position={2}
								value={values.orderIds}
								handleOrderIdsChange={handleOrderIdsChange}
								valids={values.validOrderIds}
								handleValidOrderIds={handleValidOrderIds}
								handleFormValidity={this.handleFormValidity}
								selectedRoom={values.selectedRoom}
							/>

							<FormControlLabel
								style={{ marginTop: 10, marginLeft: 8, marginRight: 8, color: "#1c1c1c" }}
								control={
									<Checkbox
										checked={this.state.checked}
										onChange={() => this.setState({ checked: !this.state.checked })}
										value={this.state.checked}
										color="primary"
									/>
								}
								label="Som romansvarlig aksepterer jeg ansvaret for eventuelle skader eller ødeleggelser inne på rommet"
							/>
						</React.Fragment>
					) : values.selectedRoom === 2 ? (
						<React.Fragment>
							<InputRow
								keyId={0}
								id={0}
								label={"Ordre ID Romansvarlig"}
								position={0}
								value={values.orderIds}
								handleOrderIdsChange={handleOrderIdsChange}
								valids={values.validOrderIds}
								handleValidOrderIds={handleValidOrderIds}
								handleFormValidity={this.handleFormValidity}
								selectedRoom={values.selectedRoom}
							/>
							<InputRow
								keyId={1}
								id={1}
								label={"Ordre ID"}
								position={1}
								value={values.orderIds}
								handleOrderIdsChange={handleOrderIdsChange}
								valids={values.validOrderIds}
								handleValidOrderIds={handleValidOrderIds}
								handleFormValidity={this.handleFormValidity}
								selectedRoom={values.selectedRoom}
							/>

							<FormControlLabel
								style={{ marginTop: 10, marginLeft: 8, marginRight: 8, color: "#1c1c1c" }}
								control={
									<Checkbox
										checked={this.state.checked}
										onChange={() => this.setState({ checked: !this.state.checked })}
										value={this.state.checked}
										color="primary"
									/>
								}
								label="Som romansvarlig aksepterer jeg ansvaret for eventuelle skader eller ødeleggelser inne på rommet"
							/>
						</React.Fragment>
					) : (
						<React.Fragment />
					)}
					{/* Username section */}
					{this.state.checked === true && this.state.formValid === true ? (
						<React.Fragment>
							<Grid container justify="center" alignItems="center">
								<Grid item xs={12}>
									<Typography style={{ marginTop: 15, marginBottom: 15 }} align="center">
										For å se billett informasjon senere trenger du/dere et gruppe brukernavn.
										Gruppen vil også få et generert passord etter fullført booking.
									</Typography>
								</Grid>
								<Grid item xs={12} align="center">
									<TextField
										required={true}
										id="username"
										label="Gruppe brukernavn"
										value={this.state.username}
										variant="outlined"
										onChange={e => this.inputChangeHandler(0, e.target.value)}
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography style={{ marginTop: 15, marginBottom: 15 }}>
										Gjerne legg ved en kommentar (ønsker til rom)
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										placeholder=""
										multiline={true}
										rows={2}
										rowsMax={4}
										variant="outlined"
										value={this.state.comment}
										onChange={e => this.inputChangeHandler(1, e.target.value)}
									/>
								</Grid>
							</Grid>
						</React.Fragment>
					) : (
						<React.Fragment />
					)}
					<div
						style={{
							width: "100%",
							padding: 10,
							marginTop: 5,
							display: "flex",
							justifyContent: "flex-end"
						}}
					>
						<Button
							disabled={!this.state.usernameFilled}
							variant="contained"
							color="primary"
							onClick={() => {
								handleChange(2, this.state.username);
								handleChange(3, this.state.comment);
								handleNext();
							}}
						>
							Fullfør booking
						</Button>
					</div>
				</Grid>
			</React.Fragment>
		);
	}
}

export default BookingForm;
