import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import React from "react";
import LoginForm from "./components/LoginForm";
import TicketSummary from "./components/TicketSummary";
import { useStyles } from "./SeeTicketStyle";


export default function SeeTicket() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [ticketDetails, setTicketDetails] = React.useState([]);

    return (
			<React.Fragment>
				<CssBaseline />
				<div className={classes.layout}>
					<Paper className={classes.paper}>
						<React.Fragment>
							{activeStep === 0 ? (
								<LoginForm nextStep={setActiveStep} setTicketDetails={setTicketDetails}/>
							) : (
								<TicketSummary values={{ ticketDetails }} />
							)}
						</React.Fragment>
					</Paper>
				</div>
			</React.Fragment>
		);
}