import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  "@media (min-height: 1130px)": {
    root: {
      flexGrow: 1,
      position: 'fixed',
      left: "0",
      bottom: "0"
    }
  },
  colHeight: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "100%"
    },
    [theme.breakpoints.up("md")]: {
      minHeight: 235
    }
  }
}));