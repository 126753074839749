import {AUTH_START, AUTH_SUCCESS, AUTH_FAILED, AUTH_LOGOUT} from "../actions/actionTypes";
import { updateState } from '../reduxUtil/reduxUtil';

const initialState = {
    token: null,
    error: null,
    authFail: false,
    loading: false
};

const authStart = (state) => {
    return updateState(state, {
        error: null,
        loading: true
    });
};

const authSuccess = (state, action) => {
    return updateState(state, {
        token: action.idToken,
        error: null,
        loading: false
    });
};

const authFailed = (state, action) => {
    return updateState(state, {
        error: action.error,
        authFail: true,
        loading: false
    });
};

const authLogout = (state) => {
    return updateState(state, {
        token: null,
    });
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_START:
            return authStart(state);

        case AUTH_SUCCESS:
            return authSuccess(state, action);

        case AUTH_FAILED:
            return authFailed(state, action);

        case AUTH_LOGOUT:
            return authLogout(state);

        default:
            return state;
    }
};

export default authReducer;